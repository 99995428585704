import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
import Testimonial from '../testimonial';
import FootPrint from '../../footprint';
// import ProjectSlick from '../projects';

const AcService = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/stt-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>STT, Mumbai</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Navi Mumbai, Maharashtra</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/atc-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ATC Tower, Kolkata Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kolkata, West Bengal</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/goa-e.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/bhita-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ESIC Medical College & Hospital, Bihta</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Patna, Bihar</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/sky-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Sky Mansion, New Delhi</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Chattarpur, New Delhi</Typography>
        </Grid>
        
    ]
    
return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className='title-div'
                // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px:!isMobile && 12.5, background: !isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography variant='body2' component={'p'} sx={{color:'#535353'}}>AC Installation</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'26px',pb:'8em'}}>
                        <Typography variant='body2' component={'h1'} className='titleStyle title-color nwhead' sx={{ pb:2 }}>AC Installation Services in Gurgaon</Typography>
                        


                        <div className='cussec'>

                        <p>Unique Engineers provides reliable AC installation services in Gurgaon for residential and commercial properties. We ensure smooth and efficient cooling solutions for diverse spaces and budgets. We handle modern equipment and skilled technicians for precise system setups. We prioritize comfort and performance while selecting the right AC models. We guarantee timely completion of installation tasks to minimize disruptions.</p>

<h2><b>Best AC Installation Services at Affordable Rate</b></h2>

<p>We deliver top-notch support for different AC brands and models. We conduct thorough site assessments before recommending customized solutions. We manage all safety protocols during each installation project. We also ensure that your building has an optimized airflow and reduced energy consumption for long-term savings. And the best part is that we provide ongoing guidance to maximize your AC’s efficiency.</p>
<h2><b>Air Conditioning Contractor in Gurgaon</b></h2>
<p>We stand out as a trusted air conditioning contractor in Gurgaon. We understand local climate demands and tailor solutions accordingly. We maintain transparent pricing structures for client satisfaction. We train our technicians to handle complex installations and repairs. We follow strict industry guidelines to ensure system longevity.</p>
<h2><b>Electrical Maintenance Services in Gurgaon</b></h2>
<p>We excel in electrical maintenance services in and around Gurgaon, keeping systems safe and efficient. We handle routine checks to detect potential faults early. We replace worn-out components and upgrade outdated systems. We also document all findings for future reference and planning. We help reduce energy bills through strategic maintenance.</p>
<h2><b>Electrician Services in Gurgaon </b></h2>
<p>Unique Engineers offers professional electrician services in and around Gurgaon for residential and commercial establishments. We troubleshoot electrical faults using systematic diagnostic tools. We install wiring and electrical panels according to established safety standards. We conduct regular inspections to prevent hazards and ensure optimal functioning. We offer emergency support for urgent electrical concerns.</p>
<h2><b>HVAC Company in Gurgaon</b></h2>
<p>We also operate as a reliable HVAC Company in and around Gurgaon. We design integrated heating, ventilation, and cooling systems for balanced indoor climates.</p>

&nbsp;
<h2><b>Why Choose Us for AC Installation and Maintenance Services in Gurgaon</b></h2>
<ul>
 	<li><b>Punctuality and Quality-Driven Services</b>: We believe in punctuality and quality-driven services. </li>
 	<li><b>Genuine Spare Parts for Guaranteed Performance</b>: We source genuine spare parts for guaranteed performance. </li>
 	<li><b>Customized Services for Specific Requirements</b>: We customize our services to align with your specific requirements. </li>
 	<li><b>Long-Term Customer Relationships Built on Trust</b>: We value long-term customer relationships built on trust. </li>
 	<li><b>Competitive Rates Without Compromising on Quality</b>: We offer competitive rates without compromising on quality.</li>
</ul>
&nbsp;
<h2><b>Why Unique Engineers Is Recommended as the Best Service Provider</b></h2>
<ul>
 	<li><b>Consistent Quality:</b> Unique Engineers stands out as the best service provider due to consistent quality across technical services.</li>
 	<li><b>Trained Professionals</b>: Our team employs trained professionals who expertly handle advanced machinery.</li>
 	<li><b>Clear Communication</b>: We keep clear lines of communication open at every project phase. We ensure transparency and efficiency.</li>
 	<li><b>Rapid Response</b>: We guarantee quick responses to service requests, especially during emergencies, to minimize operational downtime.</li>
 	<li><b>Regulatory Compliance</b>: We strictly follow national and local regulations to maintain safety and compliance.</li>
 	<li><b>Commitment to Excellence</b>: Our commitment to excellence, adaptability, and reliability sets us apart from competitors.</li>
 	<li><b>Customer Satisfaction</b>: We focus on customer satisfaction and superior outcomes. Hence, we build lasting relationships based on trust and consistent performance.</li>
 	<li><b>Leading Reputation</b>: This approach cements our reputation as a leading service provider.</li>
</ul>
<h2><b>Other Essential Services</b></h2>
<ul>
 	<li>We believe in comprehensive support for your building's essential systems, ensuring efficient operation and safety.</li>
 	<li>We integrate plumber service in Gurgaon for prompt waterline repairs, installations, and routine maintenance.</li>
 	<li>We are one of the leading fire fighting contractors. We develop robust safety protocols and specialized firefighting solutions.</li>
 	<li>We offer MEP Engineering services to streamline mechanical, electrical, and plumbing frameworks for increased efficiency.</li>
 	<li>We reinforce every service with quality assurance and dedicated support to ensure reliable, long-lasting outcomes.</li>
 	<li>We prioritize responsive customer care, enabling clients to address issues promptly and avoid unnecessary downtime.</li>
 	<li>We combine expertise with cutting-edge technology to deliver customized, budget-friendly solutions for diverse project needs.</li>
</ul>
<p>Unique Engineers delivers quality-driven AC installation services in and around Gurgaon. We position ourselves as a dependable air conditioning contractor in Gurgaon. We expand our expertise through electrical maintenance services in Gurgaon. We also address plumbing needs, fire safety measures, and integrated building solutions. Contact us today to get efficient, cost-effective, and long-lasting outcomes.</p>

                        </div>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px: !isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px:!isMobile ? 10 : 0, pt: !isMobile ? '5em' : '3em', pb: !isMobile ? '5em' : '3em'  }}>
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:'1em', textAlign: 'center'}}>Safety served with comfort</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb: !isMobile && '2em' }}>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="4642210061">4</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stadiums <br/> made spacious</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">6</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8641218062">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stress-free and <br/> comfortable hospitals </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="5612210038">5</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8824918502">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Industries <br/> innovated for</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FootPrint title='Our projects' imageArray = {slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Testimonial title={'Words of satisfaction'}  />

            <> 
            <Grid item  className='title-div'
            // sx={{px:!isMobile ? 10 : 0, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
            <Grid item sx={{px: !isMobile && 12.5}}>
            <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '8em' : '6em'}}>
            <div container style={{width:'100%', justifyContent:'center', margin:'0px 0px 0px'}}>
            <div class="cussec cusmargnsec2"> 
 
            <h3><b>FAQs</b></h3>
<ol>
 	<li><b> How quickly can you install an AC unit in Gurgaon?</b>
<p><span>We generally complete AC installations in one to two days, depending on system complexity.</span></p></li>
 	<li><b> Do you offer annual maintenance for newly installed air conditioners?</b>
<p><span>We provide comprehensive annual maintenance packages for consistent cooling and peak performance.</span></p></li>
 	<li><b> Can you handle large commercial electrical maintenance tasks?</b>
<p><span>We manage extensive commercial electrical services using experienced teams and high-grade tools.</span></p></li>
 	<li><b> What sets Unique Engineers apart from other contractors in Gurgaon?</b>
<p><span>We focus on reliability, transparent pricing, and customized solutions that prioritize customer satisfaction.</span></p></li>

<li><b> Do you assist with replacements for outdated AC systems?</b>
<p><span>We evaluate system conditions and recommend suitable replacements for improved efficiency and reliability.</span></p></li>
</ol>

            </div>
            </div>   
            </Grid></Grid></Grid>
            </>

        </Grid>
    </Grid>
)

}

export default AcService;