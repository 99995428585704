import React from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import { Helmet } from 'react-helmet';
import FireFighting from '../../components/services/firefighting'

const FIREFIGHTING = () => {

    return (
        <Grid container style={{width:'100%', justifyContent:'center'}}>
            <Helmet>
<title>Fire Fighting Contractors & Consultants in Gurgaon | Unique Engineers</title>
<meta name="description" content="Unique Engineers, top fire fighting contractors in Gurgaon, specialize in advanced fire protection systems. Trusted fire fighting consultants for safety solutions." />
<link rel="canonical" href="https://www.uniqueengineersltd.com/services/fire-fighting-contractors-in-gurgaon/" />
            </Helmet>
             <Banner img={'/assets/images/firefighting-banner.jpg'} title= 'React out and Connect' />
            <FireFighting />
        </Grid>
    )

}

export default FIREFIGHTING;