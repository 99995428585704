import React from 'react';
import './App.css';
import './number.css';

import Header from './components/header';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/footer';
import Home from './pages/home';
import AboutUs from './pages/aboutus';
import Contact from './pages/contactus';
import CareerPage from './pages/career';
import Corporate from './pages/corporatecompliance';
import Services from './pages/sevices';
import Products from './pages/products';
import Projects from './pages/projects';
import CaseStudy from './pages/casestudy';
import Leadership from './pages/leadership';
import OurApproach from './pages/ourapproach';
import SocialCommitment from './pages/socialcommitment'
import ApplyOnline from './pages/career/applyonline';
import Privacy from './pages/privacy';
import BlogPage from './pages/blogs/blogpage';
import Blogs from './pages/blogs';
import InvestorRelation from './pages/investorRelationship';
import HvacService from './components/services/hvac';
import HVAC from './pages/sevices/hvac';
import { Plumbing } from '@mui/icons-material';
import PlumbingService from './components/services/plumbing';
import PLUMBING from './pages/sevices/plumbing';
import FireFighting from './components/services/firefighting';
import FIREFIGHTING from './pages/sevices/firefighting';
import HtltService from './components/services/htlt';
import HTLT from './pages/sevices/htlt';
import AC from './pages/sevices/ac';
import Thankyou from './pages/thankyou';
import { Helmet } from 'react-helmet';



const App=() => {

const elementRoute = <Routes>
  <Route path={'/'} exact element={<Home />} />
  <Route path={'/about'} element={<AboutUs />} />
  <Route path={'/applyonline/:name'} element={<ApplyOnline />} />
  <Route path={'/case-study'} element={<CaseStudy />} />
  <Route path={'/careers'} element={<CareerPage />} />
  <Route path={'/contact'} element={<Contact />} />
  <Route path={'/corporate-compliance'} element={<Corporate />} />
  <Route path={'/services/:id'} element={<Services />} />
  <Route path={'/services/'} element={<Services />} />
  <Route path={'/services/hvac-company-in-gurgaon/'} element={<HVAC />} />
  <Route path={'/services/plumbing-services-in-gurgaon/'} element={<PLUMBING />} />
  <Route path={'/services/fire-fighting-contractors-in-gurgaon'} element={<FIREFIGHTING />} />
  <Route path={'/services/electrician-services-in-gurgaon'} element={<HTLT />} />
  <Route path={'/services/ac-installation-services-in-gurgaon'} element={<AC />} />

  <Route path={'/thankyou'} element={<Thankyou />} />
  
  <Route path={'/projects'} element={<Projects />} />
  <Route path={'/products'} element={<Products />} />
  <Route path={'/our-approach'} element={<OurApproach />} />
  <Route path={'/leadership'} element={<Leadership />} />
  {/* <Route path={'/blogs/:name'} element={<BlogPage />} /> */}
  {/* <Route path={'/blogs'} element={<Blogs />} /> */}
  <Route path={'/privacy-policy'} element={<Privacy />} />
  <Route path={'/investors/name-change'} element={<InvestorRelation />} />

  <Route path={'/social-commitment'} element={<SocialCommitment />} />
  <Route path={'*'} element={<Home />} />
</Routes>


  return (
    <div className="App">
      <Header />
      <Helmet>
<title>MEP Engineering Services in Gurgaon | MEP Consultants | Unique Engineers</title>
<meta name="description" content="Unique Engineers is your trusted partner for MEP Engineering Services in Gurgaon. Our MEP consultants deliver exceptional service with a focus on quality and efficiency." />
<meta name="keywords" content="MEP Engineering Services, MEP Consultants in gurgaon, Mep Services in Gurgaon" />
<link rel="canonical" href="https://www.uniqueengineersltd.com/" />      
      </Helmet>
      {elementRoute}
      <Footer />
    </div>
  );
}

export default App;
