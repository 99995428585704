import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
// import ProjectSlick from '../projects';
import FootPrint from '../../footprint';


import Testimonial from '../testimonial';


const PlumbingService = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/plumbing/camellia-p.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>DLF Camellias, Gurugram </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Gurugram, Haryana </Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/plumbing/bits-p.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>BITSoM, Mumbai </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Mumbai, Maharashtra </Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/plumbing/central-p.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Central Vista, Common Central Secretariat</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>New Delhi</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/plumbing/goa-p.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>
        
    ]
    
return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className='title-div'
                // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px:!isMobile && 12.5, background: !isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography sx={{color:'#535353'}}>Plumbing</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em',pb:'8em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{pb:2}}>Plumbing</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            Plumbing has largely been provided by the unorganized sector and can thus be of poor quality. Recognizing the challenges of this sector and its importance to customers, we envisioned instilling into it, our professionalism and distinctiveness. Our dedicated team of plumbing experts forms the backbone of our high-quality plumbing services.
                        <br/><br/>
                            With attention to detail and adherence to industry benchmarks, our experts ensure the flawless installation, maintenance and repair of plumbing systems within buildings. We acknowledge the pivotal role that efficient plumbing plays in the smooth operation of any project. We therefore prioritize precision in every aspect of our plumbing services. By offering a comprehensive suite of plumbing solutions including water supply systems, drainage systems, sanitary fixtures and water heating solutions, we eliminate the need for clients to engage multiple contractors or rely on unorganized service providers.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Dedicated team of plumbing experts ensuring precision and adherence to industry standards</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Eliminating the need for multiple contractors with a comprehensive plumbing solutions</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Incorporating modern techniques, high-quality materials, and advanced equipment for durability</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Commitment to professionalism, expertise, and client satisfaction in the plumbing sector.</li>
                        </ul> 


                        <div className='cussec'>

                        <h1>Plumber Service in Gurgaon</h1>
<p><span>Unique Engineers Ltd is the only name which everybody relies upon for dependable plumbing services in Gurgaon. We offer excellent plumbing solutions giving more emphasis on residential, commercial, and industrial client requirements. Our primary focus remains quality as well as satisfactory service, within a scheduled timeframe by our team of experts. The complexity ranges from minor plumbing repairs to huge installations. It is why the customers seek service from Unique Engineers Ltd for the most desired plumbing solutions in Gurgaon.</span></p>
<h3><b>Best Plumbing Services in Gurgaon</b></h3>
<p><span>We at Unique Engineers Ltd are proud to offer the </span>best plumbing services in Gurgaon<span>. We understand the need for a well-kept plumbing system for residential as well as commercial buildings. Our team delivers top-notch solutions from fixing leaks to installing new pipelines and routine maintenance. Our goal is to ensure that your plumbing systems run smoothly and save you precious time and money.</span></p>
<h3><b>Complete Plumbing Maintenance and Repair Solutions</b></h3>
<p><span>Our plumbing service caters to all sectors-from residential to industrial and commercial premises. Our full-service solution provides assurance that the systems under our care will remain in prime conditions.</span></p>

<p><b>Residential Plumbing Solutions</b></p>

<p><span>We will undertake the installation and replacement of water pipes and will handle issues in the residence from faucet drips, drain unclogging up to repairing most household common concerns with quick prompt service and spotless water flows.</span></p>

<p><b>Commercial Plumbing Solutions</b></p>

<p><span>Businesses need strong plumbing systems so that the running of business will not be hindered. We are here to install, fix, and conduct preventive maintenance on commercial places. Whether it's a restaurant, office, or retail outlet, we've got you covered.</span></p>

<p><b>Industrial Plumbing Solutions</b></p>

<p><span>Industrial facilities demand advanced plumbing systems to handle heavy operations. We offer custom-designed solutions for industries, ensuring safety and operational standards.</span></p>
<h3><b>Why Should You Choose Unique Engineers as Your Plumbing Service Provider in Gurgaon?</b></h3>
<p><span>Selecting the best plumbing service provider can really make a lot of difference to your systems' efficiency. Here is why Unique Engineers Ltd stands as the ideal option:</span></p>

<ul>
 	<li aria-level="1"><b>Experienced Professionals</b><span>: Our team is composed of highly experienced plumbers who have spent years dealing with challenging plumbing issues.</span></li>
 	<li aria-level="1"><b>Custom Solutions</b><span>: We recognize that every place has its different requirements. Our services are designed according to your requirements.</span></li>
 	<li aria-level="1"><b>Cost-effective Solution</b><span>: We provide solutions without raising the bar on the service cost.</span></li>
 	<li aria-level="1"><b>Rapid Response</b><span>: We ensure quick service because we understand the problem of plumbing causes discomfort.</span></li>
 	<li aria-level="1"><b>Quality Control</b><span>: All the services that we offer come with rigorous quality checks to ensure that solutions will last for long.</span></li>
</ul>

<h3><b>Our Plumbing Services in Gurgaon</b></h3>
<p><span>Unique Engineers Ltd provides a wide range of plumber services for all your plumbing needs.</span></p>
<ul>
 	<li aria-level="1"><b>Plumbing Repairs</b><span>: Our team repairs all types of plumbing. We fix leaky pipes, broken pipes, and faulty taps.</span></li>
 	<li aria-level="1"><b>Pipeline Installations</b><span>: We have the expertise to install pipelines. We work on new constructions and replace old or damaged ones in old buildings.</span></li>
 	<li aria-level="1"><b>Drain Cleaning Services</b><span>: We also clear clogged drains without causing further damage to the buildings.</span></li>
 	<li aria-level="1"><b>Water Heater Services</b><span>: We install, repair, and maintain water heaters. You can get a steady supply of hot water.</span></li>
 	<li aria-level="1"><b>Emergency Plumbing Services</b><span>: We provide 24/7 support for plumbing emergencies to help you during emergencies.</span></li>
</ul>
<h3><b>MEP Consultants in Gurgaon: Services by the Best HVAC Company in Gurgaon</b></h3>
<ul>
 	<li aria-level="1"><b>HVAC Services</b><span>: </span><span>HVAC is offered for both residential and commercial premises. The objective is temperature and ventilation efficiency in the property.</span></li>
 	<li aria-level="1"><b>Fire Fighting Services</b><span>: </span><span>Trusted </span>fire fighting contractors<span>, we plan, install and maintain fire safety systems to save your property.</span></li>
 	<li aria-level="1"><b>Electrician Services</b><span>: </span><span>We also offer electrician services in Gurgaon for installation, repairs, and even maintenance.</span></li>
 	<li aria-level="1"><b>Air Conditioner Installation Services in Gurgaon</b><span>: </span><span>Here, we present you with very reliable AC installation services in Gurgaon and ensure smooth, hassle-free cool system setup.</span></li>
</ul>
<h3><b>Why Our Plumbing Services Stand Apart</b></h3>
<p><span>Unique Engineers Ltd is known to provide reliable and efficient plumbing solutions. We keep the satisfaction of our customers above all by giving them quality service and quick responses. Our teams use modern tools and techniques to get precise and durable results.</span></p>
<h3><b>Get in Touch with Unique Engineers Ltd</b></h3>
<p><span>Get hold of the plumbing service Gurgaon will feel proud about only at Unique Engineers Ltd. Where our supreme intention is for plumbing solutions on proper rates, regular maintenance can also be acquired under the support of our experts available for repairing service in urgent case. We assure you expertise handling for all of your plumbing issues.</span></p>

<p><span>At Unique Engineers Ltd, we are combining technical knowledge with excellent customer service to give you the unmatched plumbing solutions. From residential repair to large commercial installations, we're here to get your systems functioning flawlessly. Contact us today for reliable and efficient plumbing services in Gurgaon!</span></p>        
                            
                        </div>


                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px: !isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px: !isMobile ? 10 : 0, pt: '5em', py: !isMobile ? '5em' : '3em', }}>
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:'1em', textAlign: 'center'}}>Solutions for comfort</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb:'2em' }}>
                            <Grid item xs={6} sm={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color ' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="9642210061">9</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Airports provided <br/> with clean air</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="8642519073">2</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="5252119170">5</span>
                                        </span>
                                        
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Luxurious <br/> 5-star hotel spaces </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="4642519073">4</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stadiums made <br/> eminently comfortable</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <ProjectSlick /> */}
            <FootPrint title='Our projects' imageArray = {slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Testimonial title={'Words of satisfaction'}  />

            <> 
            <Grid item  className='title-div'
            // sx={{px:!isMobile ? 10 : 0, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
            <Grid item sx={{px: !isMobile && 12.5}}>
            <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '8em' : '6em'}}>
            <div container style={{width:'100%', justifyContent:'center', margin:'0px 0px 0px'}}>
            <div class="cussec cusmargnsec"> 
            <h2><b>FAQs</b></h2>

<p><b>Can plumbers handle both residential and commercial work?</b></p>

<p><span>Yes, plumbers under MEP can handle residential, commercial, and even industrial plumbing needs efficiently.</span></p>

<p><b>Do plumbers under MEP handle emergency services?</b></p>

<p><span>Most MEP plumbing teams provide emergency services like fixing leaks or unclogging drains anytime.</span></p>

<p><b>Can plumbers install water heaters or pipelines?</b></p>

<p><span>Yes, plumbers under MEP services can install water heaters, new pipelines, and replace damaged ones.</span></p>

<p><b>Are plumbing services affordable?</b></p>

<p><span>Yes, most MEP service providers offer cost-effective plumbing solutions without compromising on quality.</span></p>

            </div>
            </div>   
            </Grid></Grid></Grid>
            </>

                        

        </Grid>
    </Grid>
)

}

export default PlumbingService;