import React from 'react';
import { Grid } from '@mui/material';
import Banner from '../../components/services/Banner';
import { Helmet } from 'react-helmet';
import PlumbingService from '../../components/services/plumbing'

const PLUMBING = () => {

    return (
        <Grid container style={{width:'100%', justifyContent:'center'}}>
            <Helmet>
<title>Best Plumber Service in Gurgaon | Unique Engineers</title>
<meta name="description" content="Get expert plumber service in Gurgaon with Unique Engineers! We provide the best plumbing services  for homes & offices. Call Today!" />
<link rel="canonical" href="https://www.uniqueengineersltd.com/services/plumbing-services-in-gurgaon/" />  
            </Helmet>
             <Banner img={'/assets/images/plumbing-banner.jpg'} title= 'React out and Connect' />
            <PlumbingService />
        </Grid>
    )

}

export default PLUMBING;