import { Grid, Typography } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';

const Clients = () => {

    const settings = {
        dots: false,
        dotsClass: 'slick-dots',
        arrows: false,
        className: 'center',
        centerMode: false,
        infinite: true,
        centerPadding: '20px',
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: false,
        speed: 500,
        responsive: [
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              dots: false,
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              dots: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 0,
              centerMode: false,
            },
          },
        ],
      };
const imgHeight = isMobile ? 40 : 60;
const sliderss = [
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center', px:10}}>
        <img src='/assets/images/clients/acil-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/airindia-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/amazon-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/amity-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/bharti-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/britannia-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'6'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/gmr-logo.png' style={{height:imgHeight}} alt="6" />
    </Grid>,
    <Grid key={'7'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/kelloggs-logo.png' style={{height:imgHeight}}  alt="7" />
    </Grid>,
    <Grid key={'9'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/marriott-logo.png' style={{height:imgHeight}} alt="9" />
    </Grid>,
    <Grid  key={'10'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/pepsi-logo.png' style={{height:imgHeight}} alt="10" />
    </Grid>,
    <Grid key={'11'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/pnb-logo.png' style={{height:imgHeight}} alt="11" />
    </Grid>,
    <Grid key={'12'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/png-logo.png' style={{height:imgHeight}} alt="12" />
    </Grid>,
    <Grid key={'12'} item sx={{display:'flex !important', justifyContent:'center'}}>
        <img src='/assets/images/clients/samsung-logo.png' style={{height:imgHeight}} alt="12" />
    </Grid>
]



return (
    <Grid item sx={{backgroundColor:'#F4F8FA', width:'100%', display:'flex', justifyContent:'center'}}>
        <Grid className='client-div' sx={{width:'100%', maxWidth:1440, pt: !isMobile? '5em' : '4em', pb: !isMobile? '7em': '5em'}}>
            <Typography variant='h4' component={'h4'} className='titleStyle title-color client-title' sx={{mb:2}}>
                            Clients we serve</Typography>
            <Grid item xs={12} sx={{mt:'3em'}}>
                <Slider className='client-slider' {...settings}>{sliderss}</Slider>
                {/* <Grid item xs={2} sm={2}>
                    <img src='/assets/images/coke.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <img src='/assets/images/schiender.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <img src='/assets/images/spaze.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <img src='/assets/images/pg.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid>
                <Grid item xs={2} sm={2}>
                    <img src='/assets/images/mariott.png' style={{width:'auto', maxWidth:'100%'}} alt="" />
                </Grid> */}
            </Grid>
            <>
<div container style={{width:'100%', justifyContent:'center', margin:'100px 0px 50px'}} className='cussec'>

<h2><b>MEP Engineering Services - Your Most Trusted Friend for Building Solutions</b></h2>

<p>When it comes to the domain of modern buildings for functionality and safety, the concept of MEP or Mechanical, Electrical, and Plumbing systems plays an important role. At Unique Engineers Ltd, we take pride as one of the leading MEP consultants in Gurgaon. We cater to not only residential requirements but also an ace in the field of commercial and industrial needs.</p>

<p>We also take special interest as fire fighters contractors and hence you can contact us for similar requirements as well. With a team of experienced design engineers, we ensure that you get the most innovative and reliable solutions.</p>

<h2><b>What Makes our MEP Services in Gurgaon the Best?</b></h2>

<p>As one of the leading providers of MEP services in Gurgaon, we ensure that you get the best technical knowledge on each of the projects. We tend to ace you with state-of-the-art technology and ensure that your MEP system is such that you can get optimal performance and energy efficiency. Some of the factors that make us better than the rest are:</p>

<p><b>Experience</b></p>

<p>The best thing about our service is that our MEP engineers are at the forefront of innovation. We have years of experience and along with that, we make use of the best software methodologies as well. Our team has created the system with such amenities that you get energy efficiency as well. They are well versed with the standard safety norms as well to get you amazing MEP systems.</p>

<p><b>Customer Centric Focus</b></p>

<p>One of the better-known things about our services is that it is quite customer-centric. Each of our services is curated in a way so that you get the most customized plans that best suit your needs. All our clients need to do is ensure that you contact us and let us know your requirements in detail.</p>

<p><b>Sustainable Commitment</b></p>

<p>We strongly believe that it is important to create those types of services that are designed in a way that reduces the impact on the environment. It can also bring down the operational costs and ensure that you can become more responsible for the choices you make.</p>

<p><b>Cost Effective</b></p>

<p>One of the things that stand apart from us is the regiment of cost-effective services that we offer. Keeping in mind the quality of the service that we offer there are no two ways about the fact that it is indeed highly affordable.</p>

<h2><b>Services We Offer as the Top MEP Consultants in Gurgaon</b></h2>

<p>As one of the leading MEP consultants in Gurgaon, we make sure that you get simply the best. Consequently, our spectrum of services is widespread keeping in mind our wide client base. Some of our key offerings include:</p>

<p><b>HVAC Solutions:</b> As a trusted HVAC company in Gurgaon, we provide end-to-end heating, ventilation, and air conditioning services, from design and installation to regular maintenance. Our HVAC solutions are designed in a way so that you get the best of services and that too at an affordable price range.</p>

<p><b>Electrical Maintenance Services in Gurgaon</b>: Our experts handle all aspects of electrical systems, ensuring uninterrupted power supply and safety compliance.</p>

<p><b>Plumbing Services:</b> Our reliable plumber service in Gurgaon ensures efficient water management systems for residential, commercial, and industrial buildings.</p>

<p><b>Firefighting Contractors</b>: We design and install robust fire protection systems to safeguard your property and occupants. We understand that firefighting can be an emergency requirement in some cases and hence our team professionals are there to assist you as and when required.</p>

<p><b>AC Installation Services in Gurgaon</b>: Whether it is a single unit or a centralized system, we provide seamless air conditioning installation services tailored to your needs. We not only specialize in the context of residential AC installation, but we also help you with commercial services as well.</p>

<p><b>Conclusion</b></p>

<p>If you want the best of electrician services in Gurgaon along with professional HVAC services, then get in touch with us at Unique Engineers Ltd today. With years of experience and a team that helps you throughout, we ensure that you can get amazing AC installation services in Gurgaon. Contact us today and our professionals will be there to help you at the earliest. </p>

&nbsp;
<h3><b>Frequently Asked Questions</b></h3>

<p><b>What are MEP engineering services?</b></p>

<p>MEP engineering services encompass the design, installation, and maintenance of mechanical, electrical, and plumbing systems in buildings, ensuring functionality and safety.</p>

<p><b>Why are MEP consultants important for a project?</b></p>

<p>MEP consultants ensure that building systems are designed efficiently and integrated seamlessly, enhancing overall performance and compliance with safety standards.</p>

<p><b>Do you offer electrical maintenance services in Gurgaon?</b></p>

<p>Yes, we provide comprehensive electrical maintenance services, including power distribution, lighting, and energy management solutions.</p>

<p><b>How do you ensure the quality of MEP services?</b></p>

<p>We follow strict quality control measures, use advanced tools, and employ experienced engineers to deliver top-notch services.</p>

</div>
</>
        </Grid>
    </Grid>

    
)

}

export default Clients;