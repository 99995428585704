import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
import Testimonial from '../testimonial';
import FootPrint from '../../footprint';
// import ProjectSlick from '../projects';

const HtltService = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/stt-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>STT, Mumbai</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Navi Mumbai, Maharashtra</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/atc-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ATC Tower, Kolkata Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kolkata, West Bengal</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/goa-e.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/bhita-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>ESIC Medical College & Hospital, Bihta</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Patna, Bihar</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/electrical/sky-e.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Sky Mansion, New Delhi</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Chattarpur, New Delhi</Typography>
        </Grid>
        
    ]
    
return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className='title-div'
                // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px:!isMobile && 12.5, background: !isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography variant='body2' component={'p'} sx={{color:'#535353'}}>Electrical</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em',pb:'8em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2 }}>Electrical</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            Our comprehensive electrical services encompass both High Tension (HT) and Low Tension (LT) systems which form a vital component of our MEP offerings. This integrated approach allows us to create unique solutions for each project and align with specific load requirements. By integrating electrical systems into our services, we ensure a holistic and efficient approach that adds significant value to the project's overall execution.
                            <br/><br/>
                            One of the key advantages of our integrated approach is the improved coordination between HVAC and electrical services. This seamless coordination leads to smoother project execution and enhanced efficiency. Our team of experienced professionals ensures that all aspects of the project, including electrical and HVAC, are planned, executed, and coordinated effectively.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Streamlined project execution and enhanced efficiency</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Depth of understanding of project intricacies</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Exceptional results with our comprehensive knowledge and track record in MEP solutions</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Project delivery that upholds the highest standards of quality and functionality.</li>
                        </ul> 


                        <div className='cussec'>

<h1>Electrician Services in Gurgaon</h1>
<p><span>Unique Engineers offers reliable electrician services in Gurgaon for residential and commercial properties. Our skilled electricians handle diverse tasks, that includes wiring, repairs, and upgrades, with strict safety protocols. We offer a quick response to your call and offer you services at the earliest. We offer effective solutions and long-term results for every electrical requirement in Gurgaon.</span></p>

&nbsp;
<h2><b>Electrical Maintenance Services in Gurgaon</b></h2>
<p><span>We provide comprehensive electrical maintenance services in Gurgaon to keep your electrical systems running smoothly. Our team inspects wiring, tests connections, and fixes faults before they escalate into bigger issues. We emphasize preventive care, which helps you reduce downtime and avoid unexpected repair expenses.</span></p>

<p><span>We customize maintenance schedules, ensuring consistent performance without disrupting daily activities. Our experts use advanced tools, which identify electrical anomalies and guarantee prompt solutions.</span></p>

&nbsp;
<h3><b>Why Hire a Professional Electrician in Gurgaon?</b></h3>
<p><span>Professional electricians ensure proper installation, which prevents short circuits and overloads. They adhere to industry standards, which safeguard property and residents from electrical hazards. Their licensed training minimizes risks, which often stem from untrained personnel handling complex systems. They bring the right tools, which streamline tasks and ensure long-lasting results. They stay updated with modern techniques, which benefit clients seeking energy-efficient and safe solutions.</span></p>

&nbsp;
<h3><b>Finding the Right Electrician in Gurgaon</b></h3>
<p><span>You should evaluate the electrician’s license, which confirms credibility and compliance with local regulations. You must check their experience, which reveals their ability to handle diverse electrical problems. You can request references, which highlight their track record and reliability. You should examine their response times, which indicate their commitment to urgent or emergency needs. You can compare quotes, which helps you find a fair price without compromising on service quality.</span></p>

&nbsp;
<h3><b>Electrician Service in Gurgaon</b></h3>
<p><span>Unique Engineers commits to delivering professional </span>electrician services in Gurgaon<span> at competitive rates. We have dedicated technicians, who bring years of industry experience to every assignment. We also prioritize safety, which drives our strict adherence to international standards and local guidelines. </span></p>

<p><span>We value transparent communication, which assures clients of our genuine advice and detailed cost estimates. We focus on innovative solutions, which combine traditional expertise with modern technology for optimal results.</span></p>

&nbsp;
<h3><b>Services Offered by Unique Engineers Electrician Services</b></h3>
<ul>
 	<li aria-level="1"><b>Wiring And Rewiring Services:</b> We offer wiring and rewiring services,<span> which ensure efficient power distribution across your property.</span></li>
 	<li aria-level="1"><b>Electrical Repairs: </b><span>We conduct electrical repairs, which fix flickering lights, faulty switches, and defective sockets promptly.</span></li>
 	<li aria-level="1"><b>Panel Upgrades: </b><span>We provide panel upgrades, which accommodate higher electricity demands in modern homes and offices.</span></li>
 	<li aria-level="1"><b>Lighting Installations: </b><span>We deliver lighting installations, which enhance ambiance and optimize energy consumption.</span></li>
 	<li aria-level="1"><b>Emergency Call-Outs:</b><span> We handle emergency call-outs, which address sudden electrical failures even during odd hours.</span></li>
</ul>
&nbsp;
<h3><b>Common Electrical Problems</b></h3>
<ul>
 	<li aria-level="1"><b>Short circuits</b><span> often occur when wires make direct contact or when insulation fails.</span></li>
 	<li aria-level="1"><b>Overloaded circuits</b><span> happen when users attach multiple devices to a single outlet.</span></li>
 	<li aria-level="1"><b>Flickering lights</b><span> result from poor connections, aging fixtures, or sudden voltage fluctuations.</span></li>
 	<li aria-level="1"><b>Tripped breakers</b><span> indicate excessive current flow, which triggers the breaker to protect your system.</span></li>
 	<li aria-level="1"><b>Loose outlets</b><span> pose fire risks, which demand immediate tightening or replacement by professionals.</span></li>
</ul>
&nbsp;
<h3><b>Hire Certified Electrical Services in Gurgaon</b></h3>
<p><span>Unique Engineers offers certified electrical solutions, which meet the highest industry benchmarks. We ensure thorough inspections, which detect hidden faults before they become serious threats. We maintain strict timelines, which respect your busy schedule and reduce downtime. We provide transparent quotes, which help you budget accurately without surprise add-ons. We stand by our completed work, which ensures lasting satisfaction and safe electrical systems.</span></p>

&nbsp;
<h3><b>Our Approach to Safety and Quality</b></h3>
<p><span>We follow standardized guidelines, which assure zero compromise on safety or component integrity. We use genuine spare parts, which extend the lifespan of your electrical system. We assign trained supervisors, who monitor progress and maintain strict compliance on every project. We conduct final checks, which confirm correct installations and seamless electrical flow. We regularly train our team, which keeps them updated with evolving technologies and best practices.</span></p>
<h3><b>Additional Services for Your Property</b></h3>
<ul>
 	<li aria-level="1"><b>Fire Fighting Contractors</b><span>: We are one of the best </span>fire fighting contractors<span> in Gurgaon, who ensure comprehensive fire safety measures for your premises. </span></li>
 	<li aria-level="1"><b>Plumber Service in Gurgaon</b><span>: Our specialists offer expert </span>plumber service in Gurgaon<span>. They address plumbing challenges swiftly. </span></li>
 	<li aria-level="1"><b>HVAC Company in Gurgaon</b><span>: We are a top </span>HVAC Company in Gurgaon<span>. Our experts optimise indoor temperature control. </span></li>
 	<li aria-level="1"><b>MEP Engineering Services</b><span>: We integrate </span>MEP Engineering Services<span>, which streamline mechanical, electrical, and plumbing systems in one go. </span></li>
 	<li aria-level="1"><b>AC Installation Services in Gurgaon</b><span>: We also offer </span>AC Installation Services in Gurgaon<span>, which ensure comfortable indoor environments.</span></li>
</ul>
&nbsp;
<h3><b>Why Choose Unique Engineers?</b></h3>
<p><span>We value punctuality, which fosters trust and reliability. We guarantee licensed workmanship, which adds an extra layer of assurance. We handle small-scale tasks and large-scale projects, which reflect our adaptability. We use advanced diagnostic tools, which allow faster and more accurate problem detection. We foster open dialogue, which keeps you informed about progress and any additional recommendations.</span></p>

&nbsp;
<h3><b>Conclusion</b></h3>
<p><span>Unique Engineers delivers electrician services in Gurgaon with precision, reliability, and professionalism. Our electrical maintenance services in Gurgaon reduce risks and promote uninterrupted operations. We combine modern technology, certified expertise, and proactive maintenance strategies to achieve excellence. </span></p>

<p><span>Our team ensures client satisfaction through transparent quotes, on-time service, and dedicated after-sales support. You can count on us for comprehensive electrical solutions that protect people, property, and investments.</span></p>

                        </div>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px: !isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px:!isMobile ? 10 : 0, pt: !isMobile ? '5em' : '3em', pb: !isMobile ? '5em' : '3em'  }}>
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:'1em', textAlign: 'center'}}>Safety served with comfort</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb: !isMobile && '2em' }}>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="4642210061">4</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stadiums <br/> made spacious</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">6</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8641218062">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stress-free and <br/> comfortable hospitals </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="5612210038">5</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8824918502">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Industries <br/> innovated for</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FootPrint title='Our projects' imageArray = {slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Testimonial title={'Words of satisfaction'}  />

            <> 
            <Grid item  className='title-div'
            // sx={{px:!isMobile ? 10 : 0, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
            <Grid item sx={{px: !isMobile && 12.5}}>
            <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '8em' : '6em'}}>
            <div container style={{width:'100%', justifyContent:'center', margin:'0px 0px 0px'}}>
            <div class="cussec cusmargnsec2"> 
 
            <h3><b>Frequently Asked Questions (FAQs)</b></h3>
<ol>
 	<li><b> How often should I schedule electrical maintenance?</b>
<p><span>You should schedule electrical maintenance annually or biannually, depending on system usage and complexity.</span></p></li>
 	<li><b> Does Unique Engineers offer emergency electrician services in Gurgaon?</b>
<p><span>Yes, we offer emergency services, which help clients handle urgent electrical issues anytime.</span></p></li>
 	<li><b> What are the common signs of electrical problems?</b>
<p><span>Flickering lights, unusual odors, and frequently tripped breakers are common warning indicators.</span></p></li>
 	<li><b> Do you handle commercial electrical projects?</b>
<p><span>Yes, we handle commercial and residential projects with equal dedication and professionalism.</span></p></li>

<li><b> Can you install energy-efficient lighting systems?</b>
<p><span>Yes, we install modern lighting solutions, which reduce electricity bills without compromising brightness.</span></p></li>
</ol>

            </div>
            </div>   
            </Grid></Grid></Grid>
            </>

        </Grid>
    </Grid>
)

}

export default HtltService;