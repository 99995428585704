import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
import Testimonial from '../testimonial';
// import ProjectSlick from '../projects';
import FootPrint from '../../footprint';

const HvacService = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/goa-h.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/aiim-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>AIIMS, Jammu</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Vijaypur Samba Distt., Jammu & Kashmir</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/central-vista-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Central Vista, Common Central Secretariat</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>New Delhi</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/hq27-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>HQ27 Bharti Reality</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Gurugram, Haryana</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/iitbhilai-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>IIT, Bhilai</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Kutelabhata, Chhattisgarh</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/nalanda-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Nalanda University, Rajgir </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Rajgir, Bihar</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/hvac/ssb-warangle-h.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>SSB Hospital,  Warangal</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Warangal, Telengana</Typography>
        </Grid>
    ]

    // const titleStyle = {fontSize:32, fontFamily:'Playfair Display', fontWeight:500, lineHeight:2}
   
return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item  className='title-div'
                // sx={{px:!isMobile ? 10 : 0, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px: !isMobile && 12.5, background:!isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography variant='body2' component={'p'} sx={{color:'#535353'}}>HVAC</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '8em' : '6em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2 }}>HVAC</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            At Unique Engineers, we stand as a beacon of expertise in the field of Air Conditioning. With two of our directors holding qualifications as HVAC specialists, our foundation is deeply rooted in knowledge and experience. Our journey has allowed us to master the intricacies of Air conditioning services across a spectrum of systems.
                            <br/><br/>
                            Our HVAC services have been implemented in diverse sectors, spanning industry segments like healthcare, hospitality, education, retail, and lifestyle. Our defining trait lies in our unwavering dedication to quality and our unwavering ability to meet project deadlines. Beyond being a service provider, we are committed to exceeding the expectations of our clients by designing innovative, energy-efficient solutions suitable to their individual needs.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Core strength in Air conditioning with our leaders being qualified HVAC specialists</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Expertise in various systems like water/air-cooled chillers, VRV systems, radiant systems, geothermal systems, and thermal storage</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Successful implementation in hospitals, hotels, airports, malls, luxury apartments, educational institutions</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Commitment to quality and on-time project completion, along with environmentally conscious practices.</li>
                        </ul> 

<div className='cussec'>
<h1>Unique Engineers Ltd - The Best HVAC Company in Gurgaon</h1>
<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root">What Makes Unique Engineers Ltd truly UNIQUE? <span>Unique Engineers is a leading </span>HVAC contractor in Gurgaon<span>. We offer energy-efficient and cost-effective HVAC solutions for residential and commercial spaces. We redefine service excellence as one of the top MEP consultants. </span></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>We offer professional </span>plumber service in Gurgaon<span>,</span> <span>expert </span>electrician services in Gurgaon<span>, and advanced HVAC solutions to meet the specific needs of your building. With years of experience and the best possible team, our experts will offer you a tailored service that meets all your unique needs. </span></p>
<h2 class="MuiTypography-root1 MuiTypography-body21 normal-text css-1fv6dm7-MuiTypography-root1"><b>Services We Offer as HVAC Consultants in Gurgaon</b></h2>
<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>You might be now wondering what is the genre of services that we offer you as </span>HVAC consultants in Gurgaon <span>and how is it different from the rest. Some of these include:</span></p>

<p class="MuiTypography-root1 MuiTypography-body21 normal-text css-1fv6dm7-MuiTypography-root1"><b>Residential HVAC Services</b><span> </span></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>Unique Engineers Ltd is one of the top </span><a href="https://www.uniqueengineersltd.com/">MEP consultants in Gurgaon</a><span>. W</span><span>e do understand that your home deserves the most comfortable and healthy environment and hence our energy-efficient HVAC services are the best for you. We offer you the best of in-house services, and that too in an affordable process.</span></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>Our services include </span>electrician services in Gurgaon<span>, right from</span><span> installation of the system along with taking care of any type of repairs. We also provide routine maintenance for your HVAC systems at an affordable price range. </span></p>

<p class="MuiTypography-root1 MuiTypography-body21 normal-text css-1fv6dm7-MuiTypography-root1"><b>Commercial HVAC Services </b></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>It is important to recognize that the need for a commercial setup and a residential setup are not similar. Consequently, you will have to make sure that you get experts and the most equipped professionals to assist you through the process. We are a leading</span> HVAC company in Gurgaon<span>. O</span><span>ur commercial HVAC services help you enhance productivity and also guarantee compliance with the latest safety standards. </span></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>What do we offer under commercial HVAC services? It includes customized HVAC system design, and installation for office and retail spaces and also complete in-depth preventive maintenance and repairs in case of any emergency requirements. </span></p>
<h2 class="MuiTypography-root1 MuiTypography-body21 normal-text css-1fv6dm7-MuiTypography-root1"><b>Why Should You Choose Us As Your Go-To HVAC Contractor in Gurgaon?</b></h2>
<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>If you are looking for the best in class HVAC contractors, feel free to get in touch with us at Unique Engineers Ltd. Wondering what makes us better than those who offer you </span>AC installation services in Gurgaon<span>? </span></p>

<p class="MuiTypography-root1 MuiTypography-body21 normal-text css-1fv6dm7-MuiTypography-root1"><b>Experienced Professionals</b></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>We take pride in the team of professionals we have and they are the most skilled consultants that you can ever find. They have been known to house years of experience and the best part is that they have their base in the technical knowledge that provides a base to their expertise. </span></p>

<p class="MuiTypography-root1 MuiTypography-body21 normal-text css-1fv6dm7-MuiTypography-root1"><b>Customized Solutions </b></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>There are no doubts about the fact that every area is unique. This means that the requirements for each of these spaces will also be very different. We tailor our solutions as such so that you can get maximum efficacy and comfort at an affordable price range.</span></p>

<p class="MuiTypography-root1 MuiTypography-body21 normal-text css-1fv6dm7-MuiTypography-root1"><b>Quality Assurance </b></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>Be rest assured, the kind of servicing that we will render is best of its kind and you do not have to wonder about the quality of the equipment or services. Each of our services and equipment goes through rigorous quality checks and only when it is correct to levy, we will work on the same. They are durable and the service guarantees you with best-in-class efficiency as 23ll. </span></p>

<p class="MuiTypography-root1 MuiTypography-body21 normal-text css-1fv6dm7-MuiTypography-root1"><b>Immediate Support </b></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>We provide quick MEP services by ensuring prompt, efficient solutions to all your mechanical, electrical, and plumbing requirements. We have the required expertise in air conditioning repair, electrical installation, and plumbing maintenance. It is our primary concern to reach you as early as possible. Just give us a call; we will help you with what you need. Our professional service team will ensure everything is done precisely, with much care, while you are looking for quality.</span></p>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>At Unique Engineers Ltd we take pride in being the top </span><span>MEP consultants in India</span><span>. We ensure quality in all our services and the superior-grade</span><span> assistance we put forth. We are one of the best </span>fire fighting contractors<span> in the Gurgaon area. So, if you have any such requirements, feel free to get in touch with us at the earliest. </span></p>
</div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px:!isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px:!isMobile ? 10 : 0, pt: '5em', py: !isMobile ? '5em' : '3em' }}>
                    <Grid item sx={{}}>
                        <Typography className='titleStyle title-color' sx={{ pb:'1em', textAlign: 'center'}}>Quality solutions across sectors</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb: !isMobile && '2em' }}>
                            <Grid item xs={4} sx={{}}>
                                
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="8642210061">9</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Airports <br/> ventilated</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="4612210038">4</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Stadiums <br/> made comfortable </Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{ pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">6</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8641218062">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Hospitals <br/> air conditioned</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FootPrint title='Our projects' imageArray={slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            {/* <ProjectSlick /> */}
            {/* <Grid item sx={{width:'100%', backgroundColor:'#D8F2FF', ml:!isMobile ? 10 : 2.5, pr:0, py:'5em'}}> */}
                {/* <Grid item sx={{pl: !isMobile ? 12.5: 2}}> */}
                    {/* <Typography className='titleStyle title-color' sx={{ pb:3}}>Our projects</Typography> */}
                    
                    
                {/* </Grid> */}
            {/* </Grid> */}
            <Testimonial title={'Words of satisfaction'}  />
       
        <> 
        <Grid item  className='title-div'
                // sx={{px:!isMobile ? 10 : 0, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
        <Grid item sx={{px: !isMobile && 12.5}}>
        <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '8em' : '6em'}}>
        <div container style={{width:'100%', justifyContent:'center', margin:'0px 0px 0px'}}>
        
        <div class="cussec">
        <h3><b>Frequently Asked Questions (FAQs) on </b><b>HVAC Company in Gurgaon</b></h3>

<ol>
 	<li className='normal-text'><b> What is the role of an HVAC contractor in Gurgaon?</b></li>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>An HVAC contractor handles the design, installation, and maintenance of heating, ventilation, and air conditioning systems for residential and commercial properties.</span></p>

 	<li className='normal-text'><b> Why should I hire HVAC consultants in Gurgaon?</b></li>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>HVAC consultants provide expert guidance to ensure you choose the right system for your needs. They also design efficient solutions that save energy and reduce costs.</span></p>

 	<li className='normal-text'><b> Do you offer AC installation services in North India?</b></li>

<p class="MuiTypography-root MuiTypography-body2 normal-text css-1fv6dm7-MuiTypography-root"><span>Yes, it is provided and we have well-experienced services for both households and commercial customers. Our technical team will efficiently install the machines.</span></p>
</ol>
</div>
            </div>
            </Grid></Grid></Grid></>
            </Grid>
    </Grid>
)

}

export default HvacService;