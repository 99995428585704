import React, { useState } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {Waypoint} from 'react-waypoint'
import Testimonial from '../testimonial';
// import ProjectSlick from '../projects';
import FootPrint from '../../footprint';

const FireFighting = () => {

    const [numberBool, setNumberBool] = useState(false);

    const slides = [
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/firefighting/bitsmum-f.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>BITSoM, Mumbai </Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Mumbai, Maharashtra </Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/firefighting/exec-f.png' alt='aiims1' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>Executive Enclave , PMO House</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>New Delhi</Typography>
        </Grid>,
        <Grid item xs={12} sx={{width:'90% !important'}}>
            <img src='/assets/images/services_image/firefighting/goa-f.png' alt='Goa Airport' style={{marginBottom:'2em'}} />
            <Typography variant='body2' className='slider-title-text' component={'h3'} sx={{mb:'0.25em'}}>MOPA International Airport</Typography>
            <Typography variant='body2' className='normal-text' component={'p'} sx={{mt:0, mb:'0.5em'}}>Goa</Typography>
        </Grid>
        
    ]

return (
    <Grid item xs={12} sx={{width:'100%'}}>
        <Grid item xs={12} sx={{display: 'flex', flexFlow: 'column', alignItems:'center'}}>
            <Grid item className='title-div'
                // sx={{px:10, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
                <Grid item sx={{px:!isMobile && 12.5, background: !isMobile && 'url(/assets/images/hvacbg.png) no-repeat', backgroundPosition:'bottom right'}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to={'/'} className='normal-text title-color' style={{textDecoration:'none'}} >Home</Link>
                        <Link to={'/services'} className='normal-text title-color' style={{textDecoration:'none'}} >Services</Link>
                        <Typography variant='body2' component={'p'} sx={{color:'#535353'}}>Firefighting</Typography>
                    </Breadcrumbs>
                    <Grid item xs={12} sx={{pt:'2em',pb:'8em'}}>
                        <Typography variant='body2' component={'h5'} className='titleStyle title-color' sx={{ pb:2}}>Firefighting</Typography>
                        <Typography variant='body2' component={'p'} className='normal-text' sx={{mb:4, lineHeight:2}}>
                            We have taken a step forward by adding firefighting services to our array of offerings. Capitalizing on our extensive experience in delivering top-notch piping solutions through our HVAC team, venturing into firefighting services has been a natural progression for us.
                        <br/><br/>
                            We understand that fire safety is non-negotiable. We utilize high-quality solutions sourced from available globally, enabling us to provide reliable and efficient fire safety systems that comply with industry standards and regulations. By combining firefighting services with HVAC and electrical services, we simplify the process for our business partners. This integrated approach streamlines coordination efforts and eliminates potential conflicts, leading to better project execution and smoother workflows.
                            <br/><br/>
                            What sets us apart
                        </Typography>
                        <ul>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Utilization of high-quality solutions from the international market to ensure reliable and efficient fire safety systems</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>Integration of fire fighting, HVAC, and electrical services streamlines coordination for clients, architects and others</li>
                            <li className='normal-text' sx={{mb:2, lineHeight:2}}>With a one-stop solution for MEP needs, project workflows are smooth, leading to efficiency and cost effective implementation.</li>
                        </ul> 

                        <div className='cussec'>
                        <h1>Fire Fighting Contractors in Gurgaon: Ensuring Comprehensive Safety Solutions</h1>
<p><span>We, at Unique Engineers, are one of the most dependable fire fighting contractors in Gurgaon. Here, we can be the provider for the total and exclusive solutions that ensure safety against any kind of fires for homes, commercial units, and industrial estates. We aim at technological supremacy, absolute adherence to compliance requirements, and highest efficiency so as to offer them with utmost protection.</span></p>
<h3><b>Leading Fire Fighting Contractors in Gurgaon for Comprehensive Safety Solutions</b></h3>
<p><span>Fire safety is vital for any building, whether residential, commercial or industrial. And Unique Engineers comes as one of the best fire fighting contractors in Gurgaon as it offers top-class fire safety service. It's a comprehensive installation and maintenance facility for fire safety systems to be sure that any premises remains fully secure and hence compliant with relevant local regulations.</span></p>
<h3><b>Unique Engineers: Your Reliable Fire Fighting Contractors in Gurgaon</b></h3>
<p><span>We at Unique Engineers have years of experience in providing customized fire safety solutions. The team consists of highly skilled engineers and technicians who evaluate your property to design a strong fire protection system. Be it installing sophisticated fire alarms, fire sprinklers, or suppression systems, Unique Engineers handles each project with precision and professionalism.</span></p>
<h3><b>Fire Fighting Services by Unique Engineers</b></h3>
<p><span>We offer a broad range of services to fulfill diverse fire safety needs. Our experts prioritize maximum safety and strict compliance.</span></p>
<ul>
 	<li>
<h4><b>Fire System Design and Consultation</b></h4>

<p><span>Our team creates fire system designs that meet high safety standards and adhere to local regulations.</span></p>
</li><li>
<h4><b>Fire Alarm and Detection Systems</b></h4>

<p><span>We install advanced alarms equipped with smoke and heat detectors to warn you at the earliest sign of danger.</span></p>
</li><li>
<h4><b>Fire Suppression Systems</b></h4>

<p><span>We deliver fire suppression solutions for industrial spaces and kitchens using the latest technologies.</span></p>
</li><li>
<h4><b>Fire Sprinkler Installation and Maintenance</b></h4>

<p><span>We install sprinkler systems which auto-activate in times of fire emergency. It ensures efficient fire control during emergencies.</span></p>
</li><li>
<h4><b>Fire Extinguishers and Hydrant Systems</b></h4>

<p><span>We supply and maintain portable fire extinguishers and hydrant systems. We offer comprehensive fire safety on your premises.</span></p>
</li><li>
<h4><b>Annual Maintenance Contracts (AMC)</b></h4>

<p><span>We provide regular inspections to keep all fire systems running properly and at all times. </span></p>
</li>
</ul>

&nbsp;
<h3><b>Our Working Process</b></h3>
<p><span>Unique Engineers approach every project through a systematic, client-centric way to ensure delivery to perfection:</span></p>
<ol>
 	<li><b>Initial Consultation:</b><span> The team visits your site to get an understanding of your fire safety requirements.</span></li>
 	<li><b>Customized Solution Design:</b><span> We work on designing a fire safety system based on the specific layout and requirements of your property.</span></li>
 	<li><b>Installation:</b><span> We employ high-quality equipment and advanced technologies for smooth installations.</span></li>
 	<li><b>Testing and Commissioning:</b><span> Our experts will test the systems to ensure compliance with safety requirements and efficiency in operations.</span></li>
 	<li><b>Regular Maintenance:</b><span> We offer continued maintenance to assure that fire protection systems last and serve reliably.</span></li>
</ol>
<h3><b>What Makes Unique Engineers Special</b></h3>
<ul>
 	<li><b>Expertise in Fire Safety</b><span>: We have a team of experienced fire fighting consultants. They deliver superior quality and modern safety solutions.</span></li>
 	<li><b>Client-Centric Approach</b><span>: We offer satisfactory services to every client. We do this by understanding their specific needs and accordingly providing services. </span></li>
 	<li><b>Advanced Technology</b><span>: We use modern equipment to ensure the best outcome in our services. We also follow international safety standards to ensure compliance.</span></li>
 	<li><b>Comprehensive Solutions</b><span>: We offer our services in design, installation, and maintenance. So, you get all the solutions for your building from us.</span></li>
</ul>
<h3><b>Additional Services Offered by Unique Engineers</b></h3>
<p><span>Apart from fire safety, Unique Engineers provides various other services to ensure your building and property remains safe and functional:</span></p>
<ul>
 	<li><b>Plumber Service in Gurgaon:</b><span> We offer services like plumbing installations, repairs, and maintenance for buildings. We service both residential and commercial properties.</span></li>
 	<li><b>HVAC Company in Gurgaon:</b><span> We offer heating, ventilation, and air conditioning solutions. We make sure that you have a comfortable and energy-efficient environment.</span></li>
 	<li><b>Electrician Services in Gurgaon:</b><span> We provide reliable electrical installations and repair services. We ensure continuous power supply and safety of the occupants.</span></li>
 	<li><b>MEP Engineering Services:</b><span> Our mechanical, electrical, and plumbing services help in optimising building functionality.</span></li>
 	<li><b>AC Installation Services in Gurgaon:</b><span> We install and maintain air conditioning systems for both residential and commercial building spaces.</span></li>
</ul>
<h3><b>Why Choose Unique Engineers?</b></h3>
<ul>
 	<li><b>Proven Track Record:</b><span> We have successfully delivered fire safety solutions for various sectors.</span></li>
 	<li><b>Affordable Services:</b><span> Our pricing is competitive without compromising quality.</span></li>
 	<li><b>24/7 Support:</b><span> Our dedicated team is always ready to assist you in emergencies.</span></li>
 	<li><b>Customized Solutions:</b><span> We offer our services to meet your specific fire safety requirements.</span></li>

<li><b>Compliance with Standards:</b><span> We ensure our services help you comply with local and international building safety regulations.</span></li></ul>

                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{backgroundColor:'#F4F8FA', mt:'-3em', position:'relative', zIndex:1, px: !isMobile ? 10 : 2.5, width:'100%', display:'flex', justifyContent:'center'}}>
                <Grid item sx={{width:'100%', px: !isMobile ? 10 : 0, pt: !isMobile ? '5em' : '3em', pb: !isMobile ? '5em' : '3em'  }}>
                    <Grid item sx={{}}>
                        <Typography variant='body2' component={'p'} className=' titleStyle title-color' sx={{pb:'1em', textAlign: 'center'}}>Fire safety brings security</Typography>
                        <Grid item sx={{width:'100%', display:'flex', pb: !isMobile && '2em' }}>
                            <Grid item xs={4} sx={{}}>
                                <Typography className=' titleStyle title-color' sx={{pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="6642212862">1</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="0641218062">0</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--3" data-fake="0692750268">0</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--4" style={{width:'100%', animation:'none'}} data-fake="+">+</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography variant='body2' component={'p'} className='normal-text' sx={{pb:2, textAlign:'center'}}>Basements <br/>ventilated</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Waypoint onEnter={() => setNumberBool(true)} />
                                <Typography className='titleStyle title-color' sx={{pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="5612210038">5</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="8639118002">8</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Industries <br/>provided solutions</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{}}>
                                <Typography className='titleStyle title-color' sx={{pb:0, fontFamily:'Inter !important', fontWeight:'700 !important', textAlign:'center'}}>
                                    { numberBool &&  <p class="numbers">
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--1" data-fake="2642210061">2</span>
                                        </span>
                                        <span class="numbers__window">
                                            <span class="numbers__window__digit numbers__window__digit--2" data-fake="5612630828">5</span>
                                        </span>
                                    </p>
                                    }
                                </Typography>
                                <Typography className='normal-text' sx={{pb:2, textAlign:'center'}}>Secure <br/>5-star hotel spaces</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* <ProjectSlick /> */}
            <FootPrint title='Our projects' imageArray = {slides} content='We offer unique solutions for every project we undertake and our experts examine the details minutely. We also take pride in providing sustainable solutions that minimize our carbon footprint or impact on environment.' />
            <Testimonial title={'Words of satisfaction'}  />


            <> 
            <Grid item  className='title-div'
            // sx={{px:!isMobile ? 10 : 0, width:'100%', maxWidth:1440, display:'flex', justifyContent:'center', pt:'2em'}}
            >
            <Grid item sx={{px: !isMobile && 12.5}}>
            <Grid item xs={12} sx={{pt:'2em', pb: !isMobile ? '8em' : '6em'}}>
            <div container style={{width:'100%', justifyContent:'center', margin:'0px 0px 0px'}}>
            <div class="cussec cusmargnsec1"> 
 
            <h3><b>Frequently Asked Questions (FAQs)</b></h3>
<ol>
 	<li><b> Why should I choose Unique Engineers for fire fighting services?</b></li>

<p><span>Unique Engineers provides comprehensive fire safety solutions using advanced technology and a client-centric approach.</span></p>

 	<li><b> Do you offer fire system maintenance services?</b></li>

<p><span>Yes, we provide regular maintenance and annual maintenance contracts to ensure system reliability.</span></p>

 	<li><b> What is included in your fire fighting services?</b></li>

<p><span>Our services include fire alarm installation, sprinkler systems, suppression systems, and consultation.</span></p>

 	<li><b> Do you handle residential and commercial projects?</b></li>

<p><span>Yes, we cater to both residential and commercial properties, offering customized fire safety solutions.</span></p>

 	<li><b> Are your services compliant with safety regulations?</b></li>

<p><span>Absolutely. All our services adhere to local and international fire safety standards.</span></p>
</ol>

            </div>
            </div>   
            </Grid></Grid></Grid>
            </>


        </Grid>
    </Grid>
)

}

export default FireFighting;